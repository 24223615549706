import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Space, Table } from "antd";
import { Box, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import qs from "qs";
import "../styles/campaignreports.css";

const getRandomuserParams = (params) => ({
  results: params.pagination?.pageSize,
  page: params.pagination?.current,
  ...params,
});

const CampaignReports = () => {
  const [data, setData] = useState([]);
  const [tabledata, setTableData] = useState([]);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 20,
    },
  });

  const [loading, setLoading] = useState(false);
  const [rowLoading, setRowLoading] = useState({});

  const navigate = useNavigate(); // Replace useHistory() with useNavigate()

  // const fetchCampaignData = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await axios.get(
  //       `/api/appscript/get?${qs.stringify(getRandomuserParams(tableParams))}`
  //     );

  //     const sortedData = response.data?.data.sort(
  //       (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  //     );
  //     // setData(sortedData);
  //     setTableData(sortedData);
  //     // setData(response.data?.data);
  //     // console.log(response.data?.data);
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     setLoading(false); // Stop loading
  //   }
  // };

  const parseMatchedData = (dataArray) => {
    const dataSummary = {
      total: 0, // Total entries processed
      sent: 0, // Entries that had a "sent" status
      delivered: 0, // Entries that had a "delivered" status
      read: 0, // Entries that ended with "read"
      failed: 0, // Entries that ended with "failed"
      unsent: 0, // Entries that did not achieve "sent" or "delivered"
    };

    dataArray.forEach((item) => {
      const statuses =
        item?.webhookResponse?.entry?.[0]?.changes?.[0]?.value?.statuses || [];

      if (statuses.length > 0) {
        dataSummary.total += 1;

        const hasSent = statuses.some((status) => status.status === "sent");
        const hasDelivered = statuses.some(
          (status) => status.status === "delivered"
        );

        if (hasSent) dataSummary.sent += 1;
        if (hasDelivered) dataSummary.delivered += 1;

        const lastStatus = statuses[statuses.length - 1];
        switch (lastStatus.status) {
          case "read":
            dataSummary.read += 1;
            break;
          case "failed":
            dataSummary.failed += 1;
            break;
          default:
            break;
        }
      } else {
        dataSummary.unsent += 1;
      }
    });

    const calculatePercentage = (value) =>
      dataSummary.total > 0
        ? ((value / dataSummary.total) * 100).toFixed(1) + "%"
        : "0.00%";

    dataSummary.sentPercentage = calculatePercentage(dataSummary.sent);
    dataSummary.deliveredPercentage = calculatePercentage(
      dataSummary.delivered
    );
    dataSummary.readPercentage = calculatePercentage(dataSummary.read);
    dataSummary.failedPercentage = calculatePercentage(dataSummary.failed);
    dataSummary.unsentPercentage = calculatePercentage(dataSummary.unsent);

    return dataSummary;
  };

  const fetchCampaignReportsData = async (ids) => {
    try {
      const requests = ids.map((id) =>
        axios.get(`/api/appscript/outside/get/${id}`).then((res) => ({
          id,
          matchedData: res.data.matchedData,
        }))
      );

      // Wait for all API calls to complete
      const results = await Promise.all(requests);

      // Process matched data and create data summaries
      const dataSummaries = results.map((result) => {
        const summary = parseMatchedData(result.matchedData);
        return { id: result.id, summary };
      });

      // Merge the data summaries with tableData based on matching IDs
      setTableData((prevTableData) => {
        const updatedTableData = prevTableData.map((tableItem) => {
          const matchingSummary = dataSummaries.find(
            (summaryItem) => summaryItem.id === tableItem._id
          );
          if (matchingSummary) {
            return { ...tableItem, summary: matchingSummary.summary };
          }
          return tableItem;
        });

        // Add any new data not already in the table
        const newData = dataSummaries
          .filter(
            (summaryItem) =>
              !prevTableData.some(
                (tableItem) => tableItem._id === summaryItem.id
              )
          )
          .map((summaryItem) => ({
            _id: summaryItem.id,
            summary: summaryItem.summary,
          }));

        return [...updatedTableData, ...newData];
      });
    } catch (error) {
      console.error("Error fetching campaign reports data:", error);
    }
  };

  const fetchCampaignData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `/api/appscript/get?${qs.stringify(getRandomuserParams(tableParams))}`
      );

      const sortedData = response.data?.data.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );

      setTableData(sortedData);

      const ids = sortedData.map((item) => item._id);

      await fetchCampaignReportsData(ids);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    fetchCampaignData();
  }, [JSON.stringify(tableParams)]);

  const handleRefreshCampaign = async (_id) => {
    try {
      // Set loading state for the specific row
      setRowLoading((prev) => ({ ...prev, [_id]: true }));

      // Fetch the latest data for the specific campaign
      const response = await axios.get(`/api/appscript/outside/get/${_id}`);
      const updatedSummary = parseMatchedData(response.data.matchedData);

      // Update the specific row in the table data
      setTableData((prevTableData) =>
        prevTableData.map((row) =>
          row._id === _id ? { ...row, summary: updatedSummary } : row
        )
      );
    } catch (error) {
      console.error("Error refreshing campaign:", error);
    } finally {
      // Reset loading state for the specific row
      setRowLoading((prev) => ({ ...prev, [_id]: false }));
    }
  };

  // const handleRefreshCampaign = async (_id) => {
  //   // Avoid redundant updates if already loading
  //   setRowLoading((prev) => {
  //     if (prev[_id]) return prev; // Skip if already loading
  //     return { ...prev, [_id]: true };
  //   });

  //   try {
  //     // Fetch and process the updated data in one step
  //     const { data } = await axios.get(`/api/appscript/get/${_id}`);
  //     const updatedSummary = parseMatchedData(data?.matchedData || []);

  //     // Update only the specific row efficiently
  //     setTableData((prevTableData) =>
  //       prevTableData.map((row) =>
  //         row._id === _id ? { ...row, summary: updatedSummary } : row
  //       )
  //     );
  //   } catch (error) {
  //     console.error(`Error refreshing campaign for ID: ${_id}`, error);
  //   } finally {
  //     // Reset the loading state for the row
  //     setRowLoading((prev) => {
  //       const { [_id]: _, ...rest } = prev; // Remove specific ID from loading state
  //       return rest;
  //     });
  //   }
  // };

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setTableData([]);
      // setData([]);
    }
  };

  const [searchQuery, setSearchQuery] = useState("");

  const searchContacts = () => {
    return data.filter((contact) => {
      return (
        contact.title &&
        contact.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
  };

  const getDataSource = () => {
    if (searchQuery) {
      return searchContacts();
    } else {
      return tabledata;
    }
  };

  const handleDeleteCampaign = async (_id) => {
    // Show SweetAlert2 confirm dialog
    const confirmed = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (confirmed.isConfirmed) {
      // Show a loading spinner while holding the action
      Swal.fire({
        title: "Deleting...",
        allowOutsideClick: false,
        allowEscapeKey: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      // Delete contact with the given ID
      axios
        .delete(`/api/appscript/delete/${_id}`)
        .then((res) => {
          fetchCampaignData();
          Swal.fire("Deleted!", "Campaign deleted successfully!", "success");
        })
        .catch((error) => {
          Swal.fire("Error", "Something went wrong!", "error");
        });
    }
  };

  const columns = [
    //Actions
    {
      title: "Action",
      fixed: "left",
      key: "action",
      width: 150,
      align: "center",
      render: (text, record) => (
        <Space size="middle">
          <button
            onClick={() => handleRefreshCampaign(record._id)}
            className="delete-btn"
            id="refresh-btn"
            disabled={rowLoading[record._id]}
          >
            <i class="ri-refresh-line"></i>
          </button>
          <button
            onClick={() => handleDeleteCampaign(record._id)}
            className="delete-btn"
          >
            <i className="ri-delete-bin-fill"></i>
          </button>
          <button
            type="button"
            className="edit-btn"
            onClick={() => handleViewCampaign(record._id, record.campaign_id)}
          >
            <i className="ri-eye-line"></i>
          </button>
        </Space>
      ),
    },
    {
      title: "Campaign Name",
      dataIndex: "campaign_id",
      key: "campaign_id",
      render: (text) => (text ? `${text.slice(0, 31)}...` : "-"),
    },
    {
      title: "Campaign Status",
      dataIndex: "summary",
      key: "summary",
      render: (text, record) => {
        const { summary } = record;

        // Provide default values for missing data
        const {
          totalPercentage = "100%",
          total = 0,
          sentPercentage = "100%",
          sent = 0,
          deliveredPercentage = "100%",
          delivered = 0,
          readPercentage = "100%",
          read = 0,
          failedPercentage = "100%",
          failed = 0,
        } = summary || {};
        return (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "10px",
              justifyContent: "space-between",
            }}
          >
            {/* Final code */}
            <div className="card-container my-3">
              <div className="campcard_reports all">
                <div className="icon">🔔</div>
                <div className="">
                  <h6 className="card_heading">All</h6>
                  {rowLoading[record._id] ? (
                    <div className="textpercentage">
                      <span className="loading-spinner">Loading...</span>
                      <div className="count">...</div>
                    </div>
                  ) : (
                    <div className="textpercentage">
                      <div className="percentage">{totalPercentage}</div>
                      <div className="count">({total})</div>
                    </div>
                  )}
                </div>
              </div>

              <div className="campcard_reports sent">
                <div className="icon">✔️</div>
                <div className="">
                  <h6 className="card_heading">Sent</h6>
                  {rowLoading[record._id] ? (
                    <div className="textpercentage">
                      <span className="loading-spinner">Loading...</span>
                      <div className="count">...</div>
                    </div>
                  ) : (
                    <div className="textpercentage">
                      <div className="percentage">{sentPercentage}</div>
                      <div className="count">({sent})</div>
                    </div>
                  )}
                </div>
              </div>
              <div className="campcard_reports delivered">
                <div className="icon">
                  <span className="tick1">✔</span>
                  <span className="tick2">✔</span>
                </div>
                <div className="">
                  <h6 className="card_heading">Delivered</h6>
                  {rowLoading[record._id] ? (
                    <div className="textpercentage">
                      <span className="loading-spinner">Loading...</span>
                      <div className="count">...</div>
                    </div>
                  ) : (
                    <div className="textpercentage">
                      <div className="percentage">{deliveredPercentage}</div>
                      <div className="count">({delivered})</div>
                    </div>
                  )}
                </div>
              </div>
              <div className="campcard_reports read">
                <div className="icon">✔</div>
                <div className="">
                  <h6 className="card_heading">Read</h6>
                  {rowLoading[record._id] ? (
                    <div className="textpercentage">
                      <span className="loading-spinner">Loading...</span>
                      <div className="count">...</div>
                    </div>
                  ) : (
                    <div className="textpercentage">
                      <div className="percentage">{readPercentage}</div>
                      <div className="count">({read})</div>
                    </div>
                  )}
                </div>
              </div>
              <div className="campcard_reports failed">
                <div className="icon">❌</div>
                <div className="">
                  <h6 className="card_heading">Failed</h6>
                  {rowLoading[record._id] ? (
                    <div className="textpercentage">
                      <span className="loading-spinner">Loading...</span>
                      <div className="count">...</div>
                    </div>
                  ) : (
                    <div className="textpercentage">
                      <div className="percentage">{failedPercentage}</div>
                      <div className="count">({failed})</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* Dummy Data */}
            {/* <div className="card-container my-3">
              <div className="campcard_reports all">
                <div className="icon">🔔</div>
                <div className="">
                  <h6 className="card_heading">All</h6>
                  <div className="textpercentage">
                    <div className="percentage">100%</div>
                    <div className="count">(800)</div>
                  </div>
                </div>
              </div>
              <div className="campcard_reports sent">
                <div className="icon">✔️</div>
                <div className="">
                  <h6 className="card_heading">Sent</h6>
                  <div className="textpercentage">
                    <div className="percentage">89%</div>
                    <div className="count">(586)</div>
                  </div>
                </div>
              </div>
              <div className="campcard_reports delivered">
                <div className="icon">
                  <span className="tick1">✔</span>
                  <span className="tick2">✔</span>
                </div>
                <div className="">
                  <h6 className="card_heading">Delivered</h6>
                  <div className="textpercentage">
                    <div className="percentage">58%</div>
                    <div className="count">(233)</div>
                  </div>
                </div>
              </div>
              <div className="campcard_reports read">
                <div className="icon">✔</div>
                <div className="">
                  <h6 className="card_heading">Read</h6>
                  <div className="textpercentage">
                    <div className="percentage">25%</div>
                    <div className="count">(200)</div>
                  </div>
                </div>
              </div>
              <div className="campcard_reports failed">
                <div className="icon">❌</div>
                <div className="">
                  <h6 className="card_heading">Failed</h6>
                  <div className="textpercentage">
                    <div className="percentage">2%</div>
                    <div className="count">(20)</div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        );
      },
    },
    {
      title: "Created On",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => new Date(createdAt).toLocaleString(),
    },
  ];

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchQuery(e.target.value);
  };

  const handleViewCampaign = (id, campaignId) => {
    navigate(`/dashboard/campaign/${id}`, { state: { campaignId } }); // Redirect to the campaign card page
  };

  return (
    <div className="page-content w-100">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 py-3">Campaign Reports</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xxl-12">
            <div id="contactList" className="card">
              <div className="card-header py-3">
                <div className="d-flex align-items-center flex-wrap gap-2">
                  <div className="d-flex flex-wrap flex-grow-1 gap-2">
                    {/* <form
                      className="d-flex"
                      onSubmit={(e) => e.preventDefault()}
                    >
                      <input
                        className="form-control me-2"
                        type="search"
                        id="search"
                        placeholder="Search"
                        aria-label="Search"
                        value={searchQuery}
                        onChange={handleSearch}
                      />
                    </form> */}
                    {/* Add Campaign Modal */}
                  </div>
                  <div className="flex-shrink-0"></div>
                </div>
                <div className="d-flex align-items-center flex-wrap gap-2 mt-2">
                  <div className="flex-shrink-0">
                    <div className="hstack text-nowrap gap-2 align-item-center"></div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <Table
                  dataSource={getDataSource()}
                  columns={columns}
                  rowKey="_id"
                  loading={loading}
                  scroll={{ x: true }}
                  style={{
                    overflowX: tabledata.length > 5 ? "scroll" : "hidden",
                  }}
                  pagination={{
                    pageSize: tableParams.pagination.pageSize,
                    position: "bottomRight",
                    showSizeChanger: true,
                    showQuickJumper: true,
                    pageSizeOptions: ["5", "10", "15", "20", "50", "100"],
                  }}
                  onChange={handleTableChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignReports;
