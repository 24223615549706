import "./App.css";
import MainLayout from "./components/MainLayout";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Protected from "./components/Protected";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import WhatsappCloud from "./pages/WhatsappCloud";
import WaTeamVersion1 from "./pages/WaTeamVersion1";
import WaTeamVersion2 from "./pages/WaTeamVersion2";
import WaTeamRedLava from "./pages/WaTeamRedLava";
import ApiResponseError from "./pages/ApiResponseError";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CampaignReports from "./pages/CampaignReports";
import CampaignCards from "./pages/CampaignCards";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        {/* <Route path="/two-factor-authentication" element={<TwoFactorAuth />} /> */}
        {/* <Route path="/signup" element={<SignUp />} /> */}
        {/* <Route path="/reset-password/:token" element={<Resetpassword />} /> */}
        {/* <Route path="/forgot-password" element={<Forgotpassword />} /> */}
        <Route path="/dashboard" element={<Protected Component={MainLayout} />}>
          {/* <Route path="*" element={<ErrorPage />} /> */}
          <Route index element={<Protected Component={Dashboard} />} />
          <Route
            path="whatsappcloud"
            element={<Protected Component={WhatsappCloud} />}
          />
          <Route
            path="wateamvone"
            element={<Protected Component={WaTeamVersion1} />}
          />
          <Route
            path="wateamvtwo"
            element={<Protected Component={WaTeamVersion2} />}
          />
          <Route
            path="apiresponseerror"
            element={<Protected Component={ApiResponseError} />}
          />
          <Route
            path="waredlava"
            element={<Protected Component={WaTeamRedLava} />}
          />
          <Route
            path="campaignreports"
            element={<Protected Component={CampaignReports} />}
          />
          <Route
            path="/dashboard/campaign/:id"
            element={<Protected Component={CampaignCards} />}
          />
        </Route>
      </Routes>
      <ToastContainer />
    </Router>
  );
}

export default App;
