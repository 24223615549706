import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table, Tag, Button } from "antd";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import "../styles/campaigncards.css";
import qs from "qs";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

const getRandomuserParams = (params) => ({
  results: params.pagination?.pageSize,
  page: params.pagination?.current,
  ...params,
});

const CampaignCards = () => {
  const { id } = useParams();
  const location = useLocation();

  const navigate = useNavigate();
  const { campaignId } = location.state || {};

  const [data, setData] = useState([]);
  const [tabledata, setTableData] = useState([]);
  const [showLoader, setShowLoader] = useState();

  // State to track the selected category
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [failedTable, setFailedTable] = useState(false);

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 20,
    },
  });

  const extractEntries = (response) => {
    const priority = {
      failed: 0,
      pending: 1,
      delivered: 2,
      read: 3,
      sent: 4,
    };

    return response.data.matchedData.map((item) => {
      const entry = {};
      const datetime = new Date(
        Date.parse(item.webhookResponse?.createdAt || new Date())
      );

      entry["timestamp"] = datetime.valueOf();
      entry["time"] = datetime.toLocaleString("sv");
      entry["from"] =
        item.webhookResponse?.entry[0]?.changes[0]?.value?.metadata
          ?.display_phone_number || "";
      entry["to"] =
        item.webhookResponse?.entry[0]?.changes[0]?.value?.statuses[0]
          ?.recipient_id || "";
      entry["type"] =
        item.webhookResponse?.entry[0]?.changes[0]?.value?.statuses[0]
          ?.conversation?.origin?.type || "";
      entry["status"] = "failed"; // Default status
      entry["timeline"] = {};

      // Sort statuses by timestamp
      const sortedStatuses = (
        item.webhookResponse?.entry[0]?.changes[0]?.value?.statuses || []
      ).sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

      // Iterate over sorted statuses to update the status and timeline
      sortedStatuses.forEach((status) => {
        entry.timeline[status.status] = status.timestamp;
        entry["status"] = status.status; // Always take the last status
      });

      entry["waId"] =
        item.webhookResponse?.entry[0]?.changes[0]?.value?.statuses[0]?.id ||
        "";
      entry["queueId"] = item.apiResponse?.message?.queue_id || "";
      entry["errors"] =
        item.webhookResponse?.entry[0]?.changes[0]?.value?.statuses[0]
          ?.errors[0]?.title || [];
      return entry;
    });
  };

  const parseMatchedData = (matchedData) => {
    const dataSummary = {
      total: 0, // Total entries processed
      sent: 0, // Entries that had a "sent" status
      delivered: 0, // Entries that had a "delivered" status
      read: 0, // Entries that ended with "read"
      failed: 0, // Entries that ended with "failed"
      unsent: 0, // Entries that did not achieve "sent" or "delivered"
    };

    matchedData.forEach((item) => {
      // Extract statuses for this entry
      const statuses =
        item.webhookResponse.entry[0]?.changes[0]?.value?.statuses || [];

      if (statuses.length > 0) {
        // Increment total for each entry with statuses
        dataSummary.total += 1;

        // Check if the timeline includes specific statuses
        const hasSent = statuses.some((status) => status.status === "sent");
        const hasDelivered = statuses.some(
          (status) => status.status === "delivered"
        );

        if (hasSent) dataSummary.sent += 1;
        if (hasDelivered) dataSummary.delivered += 1;

        // Consider the last status for final state calculations
        const lastStatus = statuses[statuses.length - 1];
        switch (lastStatus.status) {
          case "read":
            dataSummary.read += 1;
            break;
          case "failed":
            dataSummary.failed += 1;
            break;
          default:
            break;
        }
      } else {
        // If no statuses, count as unsent
        dataSummary.unsent += 1;
      }
    });

    // Calculate percentages
    const calculatePercentage = (value) =>
      dataSummary.total > 0
        ? ((value / dataSummary.total) * 100).toFixed(1) + "%"
        : "0.00%";

    dataSummary.sentPercentage = calculatePercentage(dataSummary.sent);
    dataSummary.deliveredPercentage = calculatePercentage(
      dataSummary.delivered
    );
    dataSummary.readPercentage = calculatePercentage(dataSummary.read);
    dataSummary.failedPercentage = calculatePercentage(dataSummary.failed);
    dataSummary.unsentPercentage = calculatePercentage(dataSummary.unsent);

    return dataSummary;
  };

  // const parseMatchedData = (matchedData) => {
  //   const dataSummary = {
  //     total: 0, // Total entries processed
  //     sent: 0, // Entries currently in "sent" status
  //     delivered: 0, // Entries currently in "delivered" status
  //     read: 0, // Entries currently in "read" status
  //     failed: 0, // Entries currently in "failed" status
  //     unsent: 0, // Entries that have not yet reached "sent"
  //   };

  //   matchedData.forEach((item) => {
  //     // Extract statuses for this entry
  //     const statuses =
  //       item.webhookResponse.entry[0]?.changes[0]?.value?.statuses || [];

  //     if (statuses.length > 0) {
  //       // Increment total for each entry with statuses
  //       dataSummary.total += 1;

  //       // Determine the most advanced status in the timeline
  //       const lastStatus = statuses[statuses.length - 1]?.status;

  //       switch (lastStatus) {
  //         case "sent":
  //           dataSummary.sent += 1;
  //           break;
  //         case "delivered":
  //           dataSummary.delivered += 1;
  //           break;
  //         case "read":
  //           dataSummary.read += 1;
  //           break;
  //         case "failed":
  //           dataSummary.failed += 1;
  //           break;
  //         default:
  //           break;
  //       }
  //     } else {
  //       // If no statuses, count as unsent
  //       dataSummary.unsent += 1;
  //     }
  //   });

  //   // Calculate percentages
  //   const calculatePercentage = (value) =>
  //     dataSummary.total > 0
  //       ? ((value / dataSummary.total) * 100).toFixed(0) + "%"
  //       : "0.00%";

  //   dataSummary.sentPercentage = calculatePercentage(dataSummary.sent);
  //   dataSummary.deliveredPercentage = calculatePercentage(
  //     dataSummary.delivered
  //   );
  //   dataSummary.readPercentage = calculatePercentage(dataSummary.read);
  //   dataSummary.failedPercentage = calculatePercentage(dataSummary.failed);
  //   dataSummary.unsentPercentage = calculatePercentage(dataSummary.unsent);

  //   return dataSummary;
  // };

  const fetchCampaignReportsData = async () => {
    try {
      setShowLoader(true);
      const response = await axios.get(
        `/api/appscript/inside/get/${id}?${qs.stringify(
          getRandomuserParams(tableParams)
        )}`
      );
      console.log("Campaign data:", response.data.matchedData);
      const parsedData = parseMatchedData(response.data.matchedData);

      setData(parsedData);
      // Run the extraction function
      const extractedData = extractEntries(response);
      // Sort the extracted data by date & time if needed
      const sortedExtractedData = extractedData.sort(
        (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
      );
      setTableData(sortedExtractedData);
      // setTableData(extractedData);
      // console.log(extractedData);
    } catch (error) {
      console.error("Error fetching campaign data:", error);
    } finally {
      setShowLoader(false);
    }
  };

  console.log("data:", data);

  useEffect(() => {
    fetchCampaignReportsData();
  }, [JSON.stringify(tableParams)]);

  const columns = [
    {
      title: "From",
      dataIndex: "from",
      key: "from",
    },
    {
      title: "To",
      dataIndex: "to",
      key: "to",
    },
    {
      title: "Creation Time",
      dataIndex: "time",
      key: "time",

      // sorter: (a, b) => {
      //   return Number(a.timestamp) - Number(b.timestamp);
      // },
    },
    ...(!failedTable
      ? [
          {
            title: "Type",
            dataIndex: "type",
            key: "type",
            ellipsis: true,
            align: "center",
          },
        ]
      : []),
    {
      title: "Last Status",
      dataIndex: "status",
      key: "status",
      align: "center",

      render: (status) => {
        let color;
        let x = status[0].toUpperCase() + status.slice(1);
        switch (x) {
          case "Delivered":
            color = "orange";
            break;
          case "Read":
            color = "green";
            break;
          case "Sent":
            color = "blue";
            break;
          case "Failed":
            color = "red";
            break;
          default:
            color = "gray";
        }
        return <Tag color={color}>{x}</Tag>;
      },
    },
    ...(failedTable
      ? [
          {
            title: "Errors",
            dataIndex: "errors",
            key: "errors",
            ellipsis: true,
            align: "center",
          },
        ]
      : []),
    {
      title: "Whatsapp ID",
      dataIndex: "waId",
      key: "waId",
      ellipsis: true,
      render: (text) => (text ? `${text.slice(0, 11)}...` : "-"),
    },

    {
      title: "Queue Id",
      dataIndex: "queueId",
      key: "queueId",
      ellipsis: true,
      render: (text) => (text ? `${text.slice(0, 11)}...` : "-"),
    },
  ];

  const refreshTable = () => {
    console.log("Table refreshed!");

    // Show loader immediately
    setShowLoader(true);

    // Call the fetch function with a delay of 1 second
    setTimeout(async () => {
      await fetchCampaignReportsData(); // Ensure the fetch function completes
      setShowLoader(false); // Hide loader after the data is fetched
    }, 1000);
  };

  const handleClick = (category) => {
    // console.log("Selected category:", category);
    if (category === "failed") {
      setFailedTable(true); // Reset the selected category on double click
    } else {
      setFailedTable(false);
    }
    setSelectedCategory(category); // Update the selected category on card click
  };

  // Filter the data based on the selected category
  const filteredData = () => {
    switch (selectedCategory) {
      case "sent":
        return tabledata.filter((item) => item.status === "sent");
      case "delivered":
        return tabledata.filter((item) => item.status === "delivered");
      case "read":
        return tabledata.filter((item) => item.status === "read");
      case "failed":
        return tabledata.filter((item) => item.status === "failed");
      default:
        return tabledata;
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setTableData([]);
    }
  };

  const getDataSource = () => {
    if (selectedCategory) {
      // If a card is selected, filter data based on the selected category
      return filteredData(selectedCategory); // Assuming filteredData filters data based on selectedCategory
    } else if (filterErrors !== "All") {
      // If an error filter is applied, return filtered data
      return filteredErrorsData();
    } else {
      // If no filter is applied, return the full data
      return tabledata;
    }
  };

  // State for selected error filter
  const [filterErrors, setFilterErrors] = useState("All");

  // Handler for dropdown filter clicks
  const handleFilterClick = (filterOption) => {
    console.log("Selected Filter:", filterOption);
    setFilterErrors(filterOption);
  };

  // Dynamically filter the data based on the selected error
  const filteredErrorsData = () => {
    if (filterErrors === "All") {
      return tabledata; // Return full data if no specific filter is applied
    }
    return tabledata.filter((item) => item.errors === filterErrors);
  };

  // const handleDownloadCSV = () => {
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: "You are about to download all data.",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes, download it!",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       setShowLoader(true);
  //       axios
  //         .get(`/api/appscript/downloadall/${id}`, { responseType: "blob" })
  //         .then((response) => {
  //           const url = window.URL.createObjectURL(new Blob([response.data]));
  //           const link = document.createElement("a");
  //           link.href = url;
  //           link.setAttribute("download", "downloadall.csv");
  //           document.body.appendChild(link);
  //           link.click();
  //           link.parentNode.removeChild(link);
  //           toast.success("File downloaded successfully!");
  //         })
  //         .catch((error) => {
  //           console.error("Error downloading the file:", error);
  //           toast.error("Error downloading the file.");
  //         })
  //         .finally(() => setShowLoader(false));
  //     }
  //   });
  // };

  const handleDownloadCSV = () => {
    Swal.fire({
      title: "Are you sure?",
      text: `You are about to download ${selectedCategory} data.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, download it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setShowLoader(true); // Show loader while fetching data

        // Define API endpoint based on the selected category
        let apiEndpoint = "";

        switch (selectedCategory) {
          case "sent":
            apiEndpoint = "sent";
            break;
          case "delivered":
            apiEndpoint = "delivered";
            break;
          case "read":
            apiEndpoint = "read";
            break;
          case "failed":
            apiEndpoint = "failed";
            break;
          default:
            apiEndpoint = "all"; // Default API for all data
            break;
        }

        // Make the API call to the selected endpoint
        axios
          .get(`api/appscript/download/${apiEndpoint}/${id}`, {
            responseType: "blob",
          })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${selectedCategory}_data.csv`); // Download file with category name
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            toast.success("File downloaded successfully!");
          })
          .catch((error) => {
            console.error("Error downloading the file:", error);
            toast.error("Error downloading the file.");
          })
          .finally(() => setShowLoader(false)); // Hide loader after request completes
      }
    });
  };

  return (
    <div className="page-content w-100">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-sm-0 py-3">
                Campaign ID : {campaignId ? `${campaignId}` : "Loading..."}
              </h4>
              <button
                className="btn btn-secondary"
                onClick={() => navigate(-1)} // Go back to the previous page
              >
                ← Back
              </button>
            </div>
          </div>
        </div>
        {/* Cards */}
        <div className="container my-3">
          <div className="d-flex align-items-center gap-2">
            <div>
              <h6>Quick reports</h6>
            </div>
            <div
              id="refresh-btn"
              style={{ fontSize: "25px" }}
              onClick={refreshTable}
            >
              <i class="ri-refresh-line"></i>
            </div>
          </div>

          <div className="card-container my-3">
            <div className="campcard all" onClick={() => handleClick("all")}>
              <div className="icon">🔔</div>
              <div className="d-flex align-items-center gap-2">
                <h6 className="fs-6">All</h6>
                <div className="textpercentage">
                  {showLoader ? (
                    <div className="loader">Loading...</div>
                  ) : (
                    <>
                      <div className="percentage">100%</div>
                      <div className="count">({data.total || 0})</div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="campcard sent" onClick={() => handleClick("sent")}>
              <div className="icon">✔️</div>
              <div className="d-flex align-items-center gap-2">
                <h6 className="fs-6">Sent</h6>
                <div className="textpercentage">
                  {showLoader ? (
                    <div className="loader">Loading...</div>
                  ) : (
                    <>
                      <div className="percentage">{data.sentPercentage}</div>
                      <div className="count">({data.sent || 0})</div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div
              className="campcard delivered"
              onClick={() => handleClick("delivered")}
            >
              <div className="icon">
                <span className="tick1">✔</span>
                <span className="tick2">✔</span>
              </div>
              <div className="d-flex align-items-center gap-2">
                <h6 className="fs-6">Delivered</h6>
                <div className="textpercentage">
                  {showLoader ? (
                    <div className="loader">Loading...</div>
                  ) : (
                    <>
                      <div className="percentage">
                        {data.deliveredPercentage}
                      </div>
                      <div className="count">({data.delivered || 0})</div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="campcard read" onClick={() => handleClick("read")}>
              <div className="icon">✔</div>
              <div className="d-flex align-items-center gap-2">
                <h6 className="fs-6">Read</h6>
                <div className="textpercentage">
                  {showLoader ? (
                    <div className="loader">Loading...</div>
                  ) : (
                    <>
                      <div className="percentage">{data.readPercentage}</div>
                      <div className="count">({data.read || 0})</div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div
              className="campcard failed"
              onClick={() => handleClick("failed")}
            >
              <div className="icon">❌</div>
              <div className="d-flex align-items-center gap-2">
                <h6 className="fs-6">Failed</h6>
                <div className="textpercentage">
                  {showLoader ? (
                    <div className="loader">Loading...</div>
                  ) : (
                    <>
                      <div className="percentage">{data.failedPercentage}</div>
                      <div className="count">({data.failed || 0})</div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Table */}
        <div className="container my-5">
          <h6>Table reports</h6>

          <div className="row">
            <div className="col-xxl-12">
              <div id="contactList" className="card">
                <div className="card-header py-3">
                  <div className="d-flex align-items-center flex-wrap gap-2">
                    <div className="d-flex flex-wrap flex-grow-1 gap-2">
                      {/* Name */}
                    </div>
                    <div className="flex-shrink-0">
                      {/* {failedTable ? (
                        <>
                          <div className="dropdown">
                            <button
                              className="btn btn-primary d-flex align-items-center gap-2 dropdown-toggle"
                              type="button"
                              id="dropdownFilterButton"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Filter
                            </button>
                            <ul
                              className="dropdown-menu"
                              aria-labelledby="dropdownFilterButton"
                            >
                              <li>
                                <button
                                  className="dropdown-item"
                                  onClick={() => handleFilterClick("All")}
                                >
                                  All
                                </button>
                              </li>
                              <li>
                                <button
                                  className="dropdown-item"
                                  onClick={() =>
                                    handleFilterClick("Message undeliverable")
                                  }
                                >
                                  Message undeliverable
                                </button>
                              </li>
                              <li>
                                <button
                                  className="dropdown-item"
                                  onClick={() => handleFilterClick("Error 2")}
                                >
                                  Error 2
                                </button>
                              </li>
                              <li>
                                <button
                                  className="dropdown-item"
                                  onClick={() => handleFilterClick("Error 3")}
                                >
                                  Error 3
                                </button>
                              </li>
                            </ul>
                          </div>
                        </>
                      ) : (
                        ""
                      )} */}
                      <button
                        className="btn btn-success btn-sm"
                        onClick={handleDownloadCSV}
                        type="primary"
                      >
                        Download{" "}
                        {selectedCategory.charAt(0).toUpperCase() +
                          selectedCategory.slice(1)}{" "}
                        Data
                      </button>
                    </div>
                  </div>
                  <div className="d-flex align-items-center flex-wrap gap-2 mt-2">
                    <div className="flex-shrink-0">
                      <div className="hstack text-nowrap gap-2 align-item-center"></div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <Table
                    dataSource={getDataSource()}
                    columns={columns}
                    rowKey="_id"
                    loading={showLoader}
                    scroll={{ x: true }}
                    style={{
                      overflowX: tabledata.length > 5 ? "scroll" : "hidden",
                    }}
                    pagination={{
                      pageSize: tableParams.pagination.pageSize,
                      position: "bottomRight",
                      showSizeChanger: true,
                      showQuickJumper: true,
                      pageSizeOptions: ["5", "10", "15", "20", "50", "100"],
                    }}
                    onChange={handleTableChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignCards;
